<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="类型" prop="typeList" >
        <a-select v-model="form.typeList" mode="multiple">
          <a-select-option :value="1">国内</a-select-option>
          <a-select-option :value="2">国外</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input type="textarea" :maxLength="150" v-model="form.title" placeholder="请输入标题"  allow-clear />
      </a-form-model-item>
      <a-form-model-item label="是否需要解释" prop="needExplain" >
        <a-radio-group v-model="form.needExplain">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="正确答案" prop="answer" >
        <a-radio-group v-model="form.answer">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否多选" prop="isMultiple" >
        <a-radio-group v-model="form.isMultiple">
          <a-radio :value="0">否</a-radio>
          <a-radio :value="1">是</a-radio>
        </a-radio-group>
      </a-form-model-item>

<!--      <a-form-model-item label="选项" prop="options" >-->
<!--        <a-button type="primary" @click="addOption()">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-table :loading="false"-->
<!--                 style="margin-top: 10px"-->
<!--                 size="middle"-->
<!--                 rowKey="key"-->
<!--                 :columns="columns"-->
<!--                 :data-source="form.optionList"-->
<!--                 :pagination="false"-->
<!--                 :bordered="false">-->
<!--          <span slot="operation" slot-scope="text, record">-->
<!--            <a @click="delOption(record.index,record)">-->
<!--            <a-icon type="delete" />删除-->
<!--            </a>-->
<!--          </span>-->
<!--          <span slot="content" slot-scope="text, record">-->
<!--           <a-input :maxLength="150" v-model="record.content"  allow-clear/>-->
<!--          </span>-->
<!--        </a-table>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="提示语" prop="prompt" >-->
<!--        <a-input :maxLength="300" v-model="form.prompt" placeholder="请输入提示语" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-item label="排序" prop="sort">
        <a-input-number :min="0" v-model="form.sort" style="width: 300px"/>
      </a-form-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLetter, addLetter, updateLetter } from '@/api/cover/letter'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: '18%',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '选项',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // 表单参数
      form: {
        id: null,
        type: null,
        typeList:[],
        title: null,
        needExplain: null,
        isMultiple: null,
        answer:null,
        prompt: null,
        createTime: null,
        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        typeList: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        answer: [
          { required: true, message: '答案不能为空', trigger: 'blur' }
        ],
        sort:[{required: true, message: '排序不能为空', trigger: 'blur'}]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.reset()
      this.open = false

    },
    // addOption(){
    //   this.form.optionList.push({
    //     index:this.form.optionList.length+1,
    //     sort:this.form.optionList.length+1,
    //     content:null
    //   })
    // },
    // delOption(index,record){
    //   this.form.optionList = this.form.optionList.filter(item => item.index !== record.index)
    //   this.form.optionList.forEach(item=>{
    //     if(item.index > index){
    //       item.index = item.index-1
    //       item.sort = item.sort -1
    //     }
    //   })
    // },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        typeList:[],
        title: null,
        needExplain: null,
        isMultiple: null,
        answer:null,
        // options: null,
        // optionList: [],
        prompt: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.open = true
      this.reset()
      this.formType = 1
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.open = true
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLetter({"id":id}).then(response => {
        this.form = response.data
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          // if(this.form.optionList){
          //   this.form.options = JSON.stringify(this.form.optionList)
          // }
          let that = this
          this.form.type = ''
          if(this.form.typeList){
            this.form.typeList.forEach(item=>{
              if(that.form.type){
                that.form.type+=','+item
              }else{
                that.form.type = item
              }
            })
          }
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLetter(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '修改成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }

            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLetter(this.form).then(res => {
              if(res.success){
                this.$message.success(
                  '新增成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }else{
                this.$message.error(res.message)
              }

            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
