import request from '@/utils/request'


// 查询cover letter列表
export function listLetter(query) {
  return request({
    url: '/cover/letter/list',
    method: 'get',
    params: query
  })
}

// 查询cover letter分页
export function pageLetter(query) {
  return request({
    url: '/cover/letter/page',
    method: 'get',
    params: query
  })
}

// 查询cover letter详细
export function getLetter(data) {
  return request({
    url: '/cover/letter/detail',
    method: 'get',
    params: data
  })
}

// 新增cover letter
export function addLetter(data) {
  return request({
    url: '/cover/letter/add',
    method: 'post',
    data: data
  })
}

// 修改cover letter
export function updateLetter(data) {
  return request({
    url: '/cover/letter/edit',
    method: 'post',
    data: data
  })
}

// 删除cover letter
export function delLetter(data) {
  return request({
    url: '/cover/letter/delete',
    method: 'post',
    data: data
  })
}
